import React from "react";
import { SiArtstation } from "react-icons/si";
import { FaFlag } from "react-icons/fa";
import { BiCodeAlt } from "react-icons/bi";
import { IoIosPaper } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import ResumeTitle from "./ResumeTitle";


const DesignandLanguagesStarthere = () => {
  return (<><div className="col-span-4">
    <ResumeTitle title="Design" icon={<SiArtstation />} />
    {/* web Design */}
    <div className="py-1">
      <div className="py-1 border-b-[1px] border-zinc-800">
        <p className="text-base text-textColor -mb-1.5">Web Development</p>
        <span className="w-full bg-zinc-600 h-1 inline-flex relative">
          <span className="w-[95%] h-full absolute top-0 left-0 bg-designColor"></span>
        </span>
      </div>
      {/* web Development */}
      <div className="py-1 border-b-[1px] border-zinc-800">
        <p className="text-base text-textColor -mb-1.5">Web Design</p>
        <span className="w-full bg-zinc-600 h-1 inline-flex relative">
          <span className="w-[90%] h-full absolute top-0 left-0 bg-designColor"></span>
        </span>
      </div>
      {/* Mobile Application */}
      <div className="py-1 border-b-[1px] border-zinc-800">
        <p className="text-base text-textColor -mb-1.5">
          Mobile Application
        </p>
        <span className="w-full bg-zinc-600 h-1 inline-flex relative">
          <span className="w-[85%] h-full absolute top-0 left-0 bg-designColor"></span>
        </span>
      </div>
      {/* UI Design */}
      <div className="py-1 border-b-[1px] border-zinc-800">
        <p className="text-base text-textColor -mb-1.5">UI Design</p>
        <span className="w-full bg-zinc-600 h-1 inline-flex relative">
          <span className="w-[80%] h-full absolute top-0 left-0 bg-designColor"></span>
        </span>
      </div>
    </div>
  </div>
    <div className="w-full h-full hidden lgl:flex justify-center items-center">
      <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
    </div>
    <div className="col-span-4">
      <ResumeTitle title="Languages" icon={<FaFlag />} />
      {/* English */}
      <div className="py-1">
        <div className="py-1 border-b-[1px] border-zinc-800">
          <p className="text-base text-textColor -mb-1.5">English</p>
          <span className="w-full bg-zinc-600 h-1 inline-flex relative">
            <span className="w-[100%] h-full absolute top-0 left-0 bg-designColor"></span>
          </span>
        </div>
        {/* Hindi */}
        <div className="py-1 border-b-[1px] border-zinc-800">
          <p className="text-base text-textColor -mb-1.5">Hindi</p>
          <span className="w-full bg-zinc-600 h-1 inline-flex relative">
            <span className="w-[90%] h-full absolute top-0 left-0 bg-designColor"></span>
          </span>
        </div>
        {/* Arabic*/}
        <div className="py-1 border-b-[1px] border-zinc-800">
          <p className="text-base text-textColor -mb-1.5">Arabic</p>
          <span className="w-full bg-zinc-600 h-1 inline-flex relative">
            <span className="w-[70%] h-full absolute top-0 left-0 bg-designColor"></span>
          </span>
        </div>
        {/* French */}
        <div className="py-1 border-b-[1px] border-zinc-800">
          <p className="text-base text-textColor -mb-1.5">French</p>
          <span className="w-full bg-zinc-600 h-1 inline-flex relative">
            <span className="w-[60%] h-full absolute top-0 left-0 bg-designColor"></span>
          </span>
        </div>
      </div>
    </div></>)
}
const CoadingandKnowledgeStarthere = () => {
  return (
    <>
      <div className="col-span-4">
        <ResumeTitle title="Language" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Typescript</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[78%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Javascript</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[96%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">C/C++</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[66%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Python</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[88%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Dart</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[88%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Java</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[89%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">PHP</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[93%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>

        </div>
        <ResumeTitle title="Frontend" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Tailwind CSS</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[86%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">ReactJs</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[85%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">NEXTJs</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[87%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">WebRTC</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[73%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <div className="col-span-4">
        <ResumeTitle title="BackEnd" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">NodeJs</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[87%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">ExpressJS</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[86%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">WebSocket</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[97%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Socket</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[75%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Flask</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[57%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">PHP</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[93%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Firebase</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[96%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
        <ResumeTitle title="Mobile Apps" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Firebase</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[99%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Java (Android)</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[96%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Flutter</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[95%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>

        </div>
      </div>




      <div className="col-span-4">
        <ResumeTitle title="Databases" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">MySQL</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[95%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">SQLite</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[95%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">MongoDB</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[95%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <div className="col-span-4">
        <ResumeTitle title="Tools" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Git</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[76%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Chocolatey</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[76%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Yolov5</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[76%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
      </div>




      <div className="col-span-4">
        <ResumeTitle title="CI/CD" icon={<BiCodeAlt />} />
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">GOOGLE CLOUD</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[88%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">AWS</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[87%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Bitbucket</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[68%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Github</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[99%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <div className="col-span-4">
        {/* <ResumeTitle title="." icon={<BiCodeAlt />} /> */}
        <h1 className="text-lg text-bodyColor uppercase font-medium flex items-center gap-2 py-3 border-b-[1px] border-b-zinc-800 select-none">
          <span className="text-designColor text-2xl">{}</span>
          CI/CD
        </h1>
        <div className="py-1">
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">NGINX</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[87%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">SSH</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[76%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Bash</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[76%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
          <div className="py-1 border-b-[1px] border-zinc-800">
            <p className="text-base text-textColor -mb-1.5">Heroku</p>
            <span className="w-full bg-zinc-600 h-1 inline-flex relative">
              <span className="w-[76%] h-full absolute top-0 left-0 bg-designColor"></span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};


const Skills = () => {
  return (
    <div className="w-full grid grid-cols-9 gap-10 lgl:gap-0 px-6">
      {/* =============== Design and Languages Start here =================== */}
      {/* <DesignandLanguagesStarthere /> */}
      {/* =============== Design and Languages End here ===================== */}

      {/* =============== Coading and Knowledge Start here ==================== */}
      <CoadingandKnowledgeStarthere />
      {/* =============== Coading and Knowledge End here ==================== */}
    </div>
  );
};

export default Skills;
